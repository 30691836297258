import "./App.css";
import { useState } from "react";

function App() {
  const [inputText, setInputText] = useState("");
  const [hashtags, setHashtags] = useState([]);

  const generateHashtags = () => {
    const lines = inputText.split(/\n/);

    const newHashtags = lines.map((line) => {
      const cleanedLine = line.trim();
      if (cleanedLine) {
        const hashtag = cleanedLine.includes(" ")
          ? cleanedLine.replace(/\s/g, "").toLowerCase()
          : cleanedLine.toLowerCase();
        return `#${hashtag}`;
      }
      return null;
    });

    setHashtags((prevHashtags) => [
      ...prevHashtags,
      ...newHashtags.filter(Boolean),
    ]);
    setInputText("");
  };

  const handleRemoveLast = () => {
    const newHashtags = [...hashtags];
    newHashtags.pop();
    setHashtags(newHashtags);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      generateHashtags();
    }
  };

  const handleCopyClick = () => {
    const resultElement = document.getElementById("result");
    resultElement.select();
    document.execCommand("copy");
  };

  return (
    <div className="box">
      <label>
        <textarea
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </label>

      <div>
        <textarea
          type="text"
          id="result"
          rows={5}
          value={hashtags.join(" ")}
          readOnly
        />
        <div>
          <button onClick={handleCopyClick} className="copy">
            Copy
          </button>
          <button onClick={handleRemoveLast} className="delete">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
